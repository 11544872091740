import axios from "axios";

const token = 'Bearer 0819c7aa72ab857f1087918a423eabe624639367a5fddfa7ec0cbe0ae69a1e1ac15a7bef5e17af84cc71c2c24db363237d57da6ac194d9bff6cbf93515e5efa9d43d5b976f8ffdd36f846122c8ceea380ce3c9d391d612af9c8b0c87a93c836dfac7119b8f1d77653dce6b69f6729e01b335faea749313f27ea1894b91a927a1';

export const postBan = async (body, endpoint) => {
   
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}` + endpoint, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
               'Authorization': token
           },
           body: body
        });
        if (!response.ok) {
            const errorData = await response.json();
            return errorData
          }
          
          return response
    } catch (error) {
        console.warn(error)
    }
}


export const getRquest = async (endpoint) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}` + endpoint);
       return response.data;
    } catch (error) {

    }
}