import { useState,useEffect } from "react";
import { case_studies_data } from "../../temp/constant";
import styles from "./CaseStudy.module.scss";
import { BreadCrumb, SocialShareBtn } from "../../components/elements/Elements";
import shareIcon from "./share.svg";
import { getRquest } from "../../api";
import { slugify } from "../../components/case_studies/CaseStudies";
import { BlogTags } from "../../components/elements/Elements";
import Loader from "../../components/loader/Loader";
import MetaTags from "../../components/meta_tags/MetaTags";
import { Helmet } from "react-helmet-async";
const data = case_studies_data;
export default function CaseStudy() {
  const [apiData, setApiData] = useState([]);
  const [selected, setSelected] = useState(0);
  const [meta, setMeta] = useState(0);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
      fetchData( 1);
  }, []);
  const fetchData = async (current) => {
      try {
          const response = await getRquest('case-studies?populate=*&pagination[page]='+current+'&pagination[pageSize]=2');
          setApiData(response.data);
          setMeta(response.meta.pagination.pageCount)
          setCurrent(response.meta.pagination.page)
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };


  const onNext = () => {
    if (selected < data.length - 1) {
      setSelected(selected + 1);
    }
  };

  const onPrev = () => {
    if (selected > 0) {
      setSelected(selected - 1);
    }
  };

  return (
    <div>
        {apiData.length > 0 ? (
            <Card
                data={apiData}
                totalCount={apiData.length}
                totalPages={meta}
                current={current} 
                onPageChange={fetchData}
            />
        ) : (
            <Loader/> // Optional: Add a loading state
        )}
    </div>
);
}


const Card = ({ data, next, onPageChange, selected, current,totalPages }) => {
  const color ={"Book Bans":"red","Restrictive Social Media Policies":"orange","Art Bans":"green","Website & App Bans":"cyan"}
 
  const breadcrumb = {"page1":{"name":"HOME","url":"/"},"page2":{"name":"CASE STUDY","url":"/case_study"}}

  return (
    <div id='case_studies' className={styles.case_study_outer}>
                   <Helmet>   
    {MetaTags("Case Study","This page lists several case studies on latest matters.", "/images/case_studies/1.jpeg", window.location.href)}
      </Helmet>
      <div className={styles.case_study_page + " " + styles.container}>
        <BreadCrumb
         breadcrumb={breadcrumb}
          theme={"dark"}
        />
        <div className={styles.case_study_title_panel}>
          Case studies <SocialShareBtn icon={shareIcon} />
        </div>
      <div className={styles.case_study_container}>
      <a href={`/${data[0].id}/${slugify(data[0].attributes.title)}`} className={"card " + styles.case_studies}>
          <div className={styles.content}>
            <div className={styles.caption}>
            <BlogTags tag={data[0].attributes.tag} bg={color[data[0].attributes.tag]}/>
            </div>
            <div className={"title " + styles.title}>{data[0].attributes.title}</div>
            <div className={styles.description}>{data[0].attributes.subtitle}</div>
            <div className={styles.gap} />
          </div>

          <img className={styles.image} src={data[0].attributes.featured_image.data != null ? `${process.env.REACT_APP_BASE_MEDIA_URL}`+data[0].attributes.featured_image.data.attributes.url: "/images/case_studies/1.jpeg"} alt={data[0].attributes.title + " Image"} />
        </a>
       {data[1] ?  <a href={`/${data[1].id}/${slugify(data[1].attributes.title)}`} className={"card " + styles.case_studies + " " + styles.reverse}>
          <div className={styles.content}>
            <div className={styles.caption}>
            <BlogTags tag={data[1].attributes.tag} bg={color[data[1].attributes.tag]}/>
            </div>
            <div className={"title " + styles.title}>{data[1].attributes.title}</div>
            <div className={styles.description}>{data[1].attributes.subtitle}</div>
            <div className={styles.gap} />
          </div>

          <img className={styles.image} src={data[1].attributes.featured_image.data != null ? `${process.env.REACT_APP_BASE_MEDIA_URL}`+data[1].attributes.featured_image.data.attributes.url: "/images/case_studies/1.jpeg"} alt={data[1].attributes.title + " Image"} />
        </a> : ""}
      </div>

      </div>
      <div className={styles.pagination}>
      {Array.from({ length: totalPages }).map((_, index) => (
        <button onClick={() => onPageChange(index + 1)} disabled={index + 1 === current} key={index}>{index + 1}</button>
      ))}
    </div>
    </div>
  );
};
