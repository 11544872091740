import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import axios from 'axios';

const LineChart2 = ({classN}) => {
  const chartContainer = useRef(null);
  const [blockCounts, setBlockCounts] = useState([]);

  useEffect(() => {
    if (!chartContainer.current) return;
  
    axios.get(`${process.env.REACT_APP_BASE_URL}arts`)
      .then(response => {
        const blocks = response.data.data; // Assuming the API response structure is an array under 'data'
        const blockCountsByYear = blocks.reduce((acc, block) => {
          const year = new Date(block.attributes.reported_date_of_block).getFullYear().toString();
          acc[year] = (acc[year] || 0) + 1;
          return acc;
        }, {});
        setBlockCounts(Object.entries(blockCountsByYear));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (!chartContainer.current || !blockCounts.length) return;

    // Prepare the data for ApexCharts
    const categories = blockCounts.map(([year]) => year)
    const data = blockCounts.map(([, count]) => count)

    const options = {
      chart: {
        zoom: {
          enabled: false,
        },
        fontFamily:"Epilogue",
        type: 'line',
        height: '500px',
        toolbar: {
            show: false,
          },
      },    
      series: [{
        name: 'Records',
        data: data,
        color: 'var(--sflc-red)'
      }],
      xaxis: {
        categories: categories,
        title: {
          text: 'Year of ban',
          style:{
            cssClass: 'apexcharts-xaxis-label',
            fontWeight:450,
            fontSize: 14,
        
          },
          offsetY:5
        },
      },
      yaxis: {
        title: {
          text: 'Number of Blocks',
          style:{
            cssClass: 'apexcharts-xaxis-label',
            fontWeight:450,
            fontSize: 14
          },
          offsetX:-5
        },
        tickAmount: 6,
      },
      toolbar: {
        show: false,
      },
      stroke: {
        // curve: 'smooth',
        width: 2,
        colors: ['#BD0101'],
      },
      grid: {
        borderColor: '#cfcccc',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
  
    };

    const chart = new ApexCharts(chartContainer.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [blockCounts]);

  return (
    <div ref={chartContainer} className={classN} style={{ width: '100%', height: '500px' }}></div>
  );
};

export default LineChart2;