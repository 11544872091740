import React, { useEffect, useState } from "react";
import styles from "./ToReadOrNot.module.scss";
import {
  StoriesPageHeader,
  StoryBody,
  StoryDownloadBtn,
} from "../../components/elements/stories_page/StoriesPage";
import BookBansHorizontalCharts from "../../components/elements/charts/BookBansHorizontalChart";
import BooksChart from "../../components/elements/charts/BooksChart";
import Books from "../../components/books/Books";
import { books, filter } from "../../temp/constant";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import MetaTags from "../../components/meta_tags/MetaTags";
import { Helmet } from "react-helmet-async";
import { getRquest } from "../../api";

export default function ToReadOrNot({setReport}) {
  const [response, setResponse] = useState(null);
  const fetchData = async () => {
    try {
      const response = await getRquest(
        "book-bans?populate=book_cover,reasons,law_used,categories,authors,language"
      ); // Replace with your API endpoint
      const apiResponse = response;
      setResponse(apiResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const breadcrumb = {"page1":{"name":"HOME","url":"/"},"page2":{"name":"STORIES","url":"/stories"}}
  useEffect(() => {
    fetchData();
  }, []);

  if (response) {
    <Helmet>   
    {MetaTags("To Read or Not","72 books banned  in 11 languages across 10 genres. Explore reasons, laws used, and initiating authority.", "./images/featured_stories/4.png", window.location.href)}
      </Helmet>
    return (
      <div className={styles.container}>
        <StoriesPageHeader
          tag_bg={"orange"}
          tag={"Book Bans"}
          title={"To Read or Not"}
          sub_heading={
            "72 books banned  in 11 languages across 10 genres. Explore reasons, laws used, and initiators."
          }
          img={"./images/featured_stories/4.png"}
          breadcrumb={breadcrumb}
          setReport={setReport}
        />
        <StoryBody
          desc={`Book bans have a long and contentious history in India, dating back to the banning of "Rangila Rasul" in 1927, often cited as the catalyst for blasphemy laws in the country. Despite gaining independence, the Indian government has often adopted a protectionist stance, carrying forward British prudence and paranoia.`}
        />
        <div className={styles.spacing_20}></div>
        <StoryBody
          desc={
            "This timeline delves into the complex landscape of book bans in India, revealing that religious sensitivities and defamation claims are the most commonly invoked reasons."
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className={"charts_container "+styles.charts_container} id="chart-container">
          <div className="charts_container_wrapper">
                 <BooksChart />
            </div>
          </div>
          <StoryBody
            desc={
              "In India, book bans are primarily enforced under Section 95 of the Criminal Procedure Code (CrPC), which grants authorities the power to prohibit publications deemed detrimental to public interest or morality. Prior to the digital era, bans often extended to import restrictions. Section 96 of the CrPC offers a legal avenue for challenging bans through High Court intervention. Additionally, laws such as Sections 499 and 295A of the Indian Penal Code, have been frequently invoked to justify bans, particularly when publications are deemed defamatory or capable of inciting religious discord. "
            }
          />

          <div className="slim_container">
            <div className={styles.different_section}>
              Explore how different laws intersect with different reasons below.
            </div>
          </div>
          <div className="charts_container" id="chart-container">
            <div className="charts_container_wrapper">
              <BookBansHorizontalCharts apiResponse={response} />
            </div>
          </div>
          <div className="container">
            <div className={styles.different_section_2}>
            Click on the tabs to explore further
            </div>
          </div>
          <Books books={response} />
          <div className="slim_container">
            <StoryDownloadBtn setReport={setReport} ban={"Book"} />
          </div>
        </div>
      </div>
    );
  }
  return (<Loader/>)
}
