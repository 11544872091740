import { useState, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';

const useFonts = (fontNames) => {
    const [fontsLoaded, setFontsLoaded] = useState(false);

    useEffect(() => {
        const fontObservers = fontNames.map((fontName) => {
            const fontObserver = new FontFaceObserver(fontName);
            return fontObserver.load();
        });

        Promise.all(fontObservers).then(() => {
            setFontsLoaded(true);
        }).catch((error) => {
            console.error('Fonts failed to load:', error);
            setFontsLoaded(true); // Fallback to show content even if fonts fail
        });
    }, [fontNames]);

    return fontsLoaded;
};

export default useFonts;
