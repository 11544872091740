import Styles from './Books.module.scss';
import { Tooltip } from "react-tooltip";
const BookPopup = ({ books, heading, description,close, law }) => {

    return (
        <div className={Styles.book_popup_container}>
            <div className={Styles.close_container} onClick={close}></div>
           <div className={Styles.book_inner_container}>
            <div className={Styles.book_close} onClick={close} >X</div>
           <div className={Styles.heading}>{heading}</div>
            <div className={Styles.description}>{description}</div>
            <div className={Styles.heading}>{law}</div>
            <div className={Styles.grid}>
                {books.map((item) => <Book key={item.id} item={item} />)}
            </div>
           </div>
        </div>
    ) 
}

const Book = ({ item }) => {
    return (
        <div className={Styles.book_container}>
            <Tooltip
              className={Styles.tooltip_class}
              id={"my-tooltip-religious-asdfa"+item.id}
            />
            <div data-tooltip-id={"my-tooltip-religious-asdfa"+item.id} data-tooltip-html={`<p><b>Title</b>: ${item.attributes.book_title}</p><p><b>Year</b>: ${item.attributes.year}</p><p><b>Authors</b>: ${item.attributes.authors.data.map((author) => author.attributes.author_name).join(', ')}</p><p><b>Language</b>: ${item.attributes.language.data.map((lang) => lang.attributes.Language).join(', ')}</p><p><b>Law used</b>: ${item.attributes.law_used.data.map((lang) => lang.attributes.law_name).join(', ')}</p>`} style={{backgroundImage:`url('${item.attributes.book_cover.data?`${process.env.REACT_APP_BASE_MEDIA_URL}`+item.attributes.book_cover.data.attributes.url : "https://cdn.pixabay.com/photo/2022/11/08/06/05/read-7577787_1280.jpg"}')`}} className={Styles.image}><img  src={item.attributes.book_cover.data?`${process.env.REACT_APP_BASE_MEDIA_URL}`+item.attributes.book_cover.data.attributes.url : "https://cdn.pixabay.com/photo/2022/11/08/06/05/read-7577787_1280.jpg"} alt={item.book_title} /></div>
            <div className={Styles.title}>{item.attributes.book_title}</div>
            {/* <div className={Styles.about}>{item.about}</div> */}

        </div>
    )
}

export default BookPopup;