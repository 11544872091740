export const large_text = 'In the first week of May 2023, it was widely reported that 14 applications were banned in India. These applications were blocked under Section 69A of the Information Technology Act, 2000, on grounds of being allegedly used for communication between bad actors in the region of Jammu and Kashmir. In the list of blocked applications were 2 widely- used FOSS applications, namely ‘Element’ and ‘Briar’ that software developers, technologists, innovators and entrepreneurs utilise on a daily basis. The use of such FOSS applications enables several benefits like enhanced interoperability, reduced costs, vendor independence, greater localization and developing local growth of the IT sector in India. The order for blocking the applications had placed unreasonable restrictions on the exercise of Freedom of Speech and Expression, and Freedom to Carry Out Trade and Business, as guaranteed under the Constitution of India. The order was also not made public which suffers from the vice of violating the Principles of Natural Justice insofar as no notice was served to the Petitioners, nor was any opportunity of hearing granted to date, as mandated under the relevant rules, i.e., Blocking Rules, 2009. ';

export const feature_storied_data = [{
    id: 4,
    image: "/images/featured_stories/4.png",
    title: "To Read or Not",
    caption_color: "#D96A6A",
    caption: "Book Bans",
    url:"to-read-or-not",
    description: "72 books banned  in 11 languages across 10 genres. Explore reasons, laws used, initiators.",
},
    {
        id: 1,
        image: "/images/featured_stories/1.png",
        title: "Reading the Fine Print",
        caption_color: "#F29454",
        caption: "Restrictive Social Media Policies",
        description: "Exploring social media restrictions and the balance between free speech and company image.",
        url: "/reading_the_fine_print"
    }, {
        id: 2,
        image: "/images/featured_stories/2.png",
        title: "Lights, Camera, Banned",
        caption_color: "#78A57099",
        caption: "Art Bans",
        description: "Illuminating India's artistic censorship landscape across film, television, and online platforms.",
        url: "/lights_camera_banned"
    }, {
        id: 3,
        image: "/images/featured_stories/3.png",
        title: "404: Website Not Found",
        caption_color: "#82D4E6",
        caption: "Website & App Bans",
        url:"404-website-not-found",
        description: "Ever encountered Error 404? Or suddenly cut off from your favourite websites? Perhaps your once-awesome website is no longer available? Let's find out.",
    }, 
];

export const case_studies_data = [
    {
        caption: "Book Bans",
        caption_color: "#D96A6A",
        title: "Rangila Rasul: The Origin of Blasphemy Laws in India",
        description: "How a publication of a book in 1927 led to reforms in India's penal code that made blasphemy illegal and may have contributed to promote the partition of India.",
        image: "/images/case_studies/1.jpeg"
    },
    {
        caption: "Book Bans",
        caption_color: "#D96A6A",
        title: "How India banned 60 Chinese Apps including Tik Tok and WeChat",
        description: "The move is part of the tit-for-tat retaliation after the Indian and Chinese militaries clashed in June 2020. The reason which was cited was national security.",
        image: "/images/case_studies/2.png"
    },
    {
        caption: "Book Bans",
        caption_color: "#D96A6A",
        title: "Blocking of Free Software Apps: Briar and Element",
        description: "The move is part of the tit-for-tat retaliation after the Indian and Chinese militaries clashed in June 2020. The reason which was cited was national security.",
        image: "/images/case_studies/3.png"
    },
];

export const overview_data = {
    // dont change format
    description: `The Free Speech Tracker website is a tool for monitoring and documenting instances of free speech violations across India. In an era where freedom of expression is increasingly under scrutiny and faces various challenges, this platform serves as a centralized repository of information for citizens, researchers, activists, and policymakers to defend our free speech.

Recognizing the evolving landscape of communication mediums and the internet's transformative impact on how information is disseminated and accessed, there's a critical need to reassess our laws and legislation on free speech. The Free Speech Tracker website aims to foster dialogue and awareness surrounding the importance of upholding free speech rights in India and reimagine our laws. By maintaining databases of judgments in the free speech realm, tracking cases that are sub-judice in court, and documenting instances of website blocking, art censorship, book bans, and cases on intermediary liability, the platform offers a detailed insight into the landscape of free expression in India.
    
This data is gathered by volunteers associated with SFLC.in through various channels such as news reports, court documents, and contributions from the public, which are then verified. It's important to note that the data collected may not encompass all instances, and we encourage citizens to use the reporting feature to notify us of any overlooked incidents.

`,
    feedback: 'Additionally, we welcome feedback, suggestions, and collaboration inquiries, and invite you to reach out to us at',
    email: 'mail@sflc.in',
};

export const faqs_data = [
    {
        id: 1,
        title: 'How is the data collected and verified for the Free Speech Tracker website?',
        description: 'The data collected is from the public, news reports, court documents, and contributions from the public. The data is then verified by volunteers associated with SFLC.in.'
    },
    {
        id: 2,
        title: 'What is the significance of Article 19(1)(a) of the Constitution of India?',
        description: large_text,
    },
    {
        id: 3,
        title: 'What should free speech rights look like?',
        description: large_text,
    },
    {
        id: 4,
        title: 'How do I report instances that are not present in this database?',
        description: large_text,
    },
    {
        id: 5,
        title: 'How can I help in promoting free speech rights in India?',
        description: large_text,
    }
];

export const credits_data = [
    {
        title: "Concept",
        names: "Test credits"
    },
    {
        title: "Research",
        names: "Test credits"
    },
    {
        title: "Data",
        names: "Test credits"
    },
    {
        title: "Research & Analysis",
        names: "Test credits"
    },
    {
        title: "Data Visualisation",
        names: "Shafali"
    },
    {
        title: "UI/UX",
        names: "Vishnu"
    },
    {
        title: "Graphic Design",
        names: "Greeshma"
    },
    {
        title: "Developement",
        names: "Orendra Singh, Vinayak Aggrawal, and Harsh Singh"
    },
    {
        title: "Deployment",
        names: "Kiran S Kunjumon"
    }
];

export const case_studies_details_data = {
    caption: "Book Bans",
    image: '/images/case_studies/3.png',
    title: "Blocking of Free Software Apps: Briar and Element",
    description: large_text,
    relevantLinks: [
        "https://sflc.in/recent-content-blocking-in-india/asdfasdf"
    ]
};

export const quiz_data = [
    {
        type: "number",
        question: "How many cuts in Udta Punjab was prescribed by CBFC?",
        instructions: "Drag the slider to estimate the amount",
        correct_answer: {
            value: 95,
            text: 'Correct! Bombay High Court later cleared the movie and asked the censor board to stop being sensitive in matters of art and curb its instinct to suggest cuts in films "routinely" and "mindlessly." At the same time, the court said "artistic freedom was not absoulute."'
        },
        wrong_answer: {
            text: 'Almost! There are actually 94. Bombay High Court later cleared the movie and asked the censor board to stop being sensitive in matters of art and curb its instinct to suggest cuts in films "routinely" and "mindlessly." At the same time, the court said "artistic freedom was not absoulute."',
            image: "/images/quiz/1.png"
        }
    },
    {
        type: "options",
        question: "Which web series has been self censored by the OTT Platform?",
        instructions: "Choose one of the following options",
        options: [
            'Tandav & Bad Boy Billionaire: India',
            'Last Week Tonight with John Oliver & Madam Secretary',
            'Sacred games & Leila'
        ],
        correct_answer: {
            value: 2,
            text: 'Yes! Episode named Modi: Last Week Tonight with John Oliver was self censored by Hotstar due its references to the CAA and the protests. CBS show Madam Secretary’s first episode of season 5, was taken down by Amazon Prime India, due to its references to Hindu nationalism and Hindu extremists.',
            image: "/images/quiz/2.png"
        },
        wrong_answer: {
            text: 'Oops! Episode named Modi: Last Week Tonight with John Oliver was self censored by Hotstar due its references to the CAA and the protests. CBS show Madam Secretary’s first episode of season 5, was taken down by Amazon Prime India, due to its references to Hindu nationalism and Hindu extremists.',
            image: "/images/quiz/3.png"
        }
    },
    {
        type: "options",
        question: "Election Comission has blocked movies on which two prime ministers?",
        instructions: "Choose one of the following options",
        options: [
            'Indira Gandhi & Narendra Modi',
            'Jawaharlal nehru & Atal Bihari Vajpayee',
            'P. V. Narasimha Rao & Manmohan Singh'
        ],
        correct_answer: {
            value: 1,
            text: `Yes! It's Indira Gandhi & Narendra Modi. Aandhi (1975) and Narendra Modi - Biopic (2023) were banned on the alleged grounds of violation of the Model Election Code of Conduct under Article 324 of the Constitution of India.`,
            image: "/images/quiz/trump.png"
        },
        wrong_answer: {
            text: `Oops! It's Indira Gandhi & Narendra Modi. Aandhi (1975) and Narendra Modi - Biopic (2023) were banned on the alleged grounds of violation of the Model Election Code of Conduct under Article 324 of the Constitution of India.`,
            image: "/images/quiz/4.png"
        }
    },
    {
        type: "options",
        question: "Which movie, despite reducing the footage to 10,966 feet from 11,602 feet, was still denied certification from CBFC and FCAT?",
        instructions: "Choose one of the following options",
        options: [
            'Final Solution',
            'Chand Bujh Gaya',
            'Aakrosh',
            'Firaaq',
            'Parzania'
        ],
        correct_answer: {
            value: 2,
            text: `Yes! However, all the movies Final Solution, Aakrosh, Firaaq, Parzania, including Chand Bujh Gaya were based on Gujarat riots and were banned.`,
            image: "/images/quiz/6.png"
        },
        wrong_answer: {
            text: `Oops! It's Chand Bujh Gaya. However, all the movies Final Solution, Aakrosh, Firaaq, Parzania, including Chand Bujh Gaya were based on Gujarat riots and were banned.`,
            image: "/images/quiz/5.png"
        }
    },
    {
        type: "number",
        question: "How many films were denied certification by CBFC during 2001-11?",
        instructions: "Choose one of the following options",
        correct_answer: {
            value: 256,
            text: `Correct! During years 2001 to 2011, a total 256 films have been denied certification by the Central Board of Film Certification (Censor Board). Most of these movies were denied certificate on grounds that they had sexually implicit material.`,
            image: "/images/quiz/7.png"
        },
        wrong_answer: {
            text: `Almost! During years 2001 to 2011, a total 256 films have been denied certification by the Central Board of Film Certification (Censor Board). Most of these movies were denied certificate on grounds that they had sexually implicit material.`,
            image: "/images/quiz/8.png"
        }
    }
];

const book = {
    image: '/images/books/1.png',
    title: "Five Past Midnight in Bhopal",
    about: "Brigadier J. P. Dalvi",
    type: "Books",
}

export const filter = [
    "People based books",
    "Place based books",
    "Incident based bans",
    "Books with Female authors",
    "Diverse Voices"
]

export const books = () => {
    const array = [];
    for (let index = 0; index < 20; index++) {
        array.push({ ...book, id: index, type: filter[Math.floor(Math.random() * 5)] })
    }
    return array;
}