import styles from './App.module.scss'
import Router from './Router';
import useFonts from './components/font_observer/FontObserver';
import Loader from './components/loader/Loader';

function App() {
  const fontsLoaded = useFonts(['Roboto Slab', 'Epilogue']);
  if (!fontsLoaded) {
    return (
        <div id="preloader" style={{ textAlign: 'center', padding: '50px', fontSize: '24px' }}>
           <Loader/>
        </div>
    );
}
  return (
    <div className={"web_1368 " + styles.App}>
      <div className={styles.container}>

        <Router />
      </div>
    </div>
  );
}

export default App;
