import { useMemo, useState } from 'react';
import Styles from './Books.module.scss';
import { Tooltip } from 'react-tooltip';
const Books = ({ books }) => {

    const [filter, setFilter] = useState("Other");
    const filteredBooks = useMemo(() => {
        if (!filter) return books.data;
    
        return books.data.filter((item) => {
          const categoryData = item.attributes?.categories?.data;
          if (categoryData && categoryData.length > 0) {
            return categoryData[0].attributes.category_name === filter;
          }
          return false;
        });
      }, [books, filter]);


    const filtersSet = new Set(); // Use a Set to keep track of unique category names
    let filters = new Set();

    books.data.forEach((item) => {
        try {
          const categoryData = item.attributes?.categories?.data;
          if (categoryData && categoryData[0]?.attributes?.category_name) {
            const categoryName = categoryData[0].attributes.category_name;
            if (filters.has(categoryName)) {
             
            } else {
              
            }
          } else {
            
          }
        } catch (error) {
          console.error('Error processing item:', error);
        }
      });
      
      // Convert the Set to an array if needed
      const filtersArray = Array.from(filters);
      filters=filtersArray
    

    return (
        <>
            <div className={Styles.container}>
                {filters.map((item) => <div key={item} className={filter === item ? Styles.active_filter : Styles.filter} onClick={() => setFilter(item)}>{item}</div>)}
            </div>
            <div className={Styles.grid + " slim_container "+ Styles.books_section}>
                {filteredBooks.map((item) => <Book key={item.id} item={item} />)}
            </div>
        </> 
    )
}

const Book = ({ item }) => {
    return (
        <div className={Styles.book_container}>
            <Tooltip
              className={Styles.tooltip_class}
              id={"my-tooltip-religious-ajsdfn"+item.id}
            />
            <div data-tooltip-id={"my-tooltip-religious-ajsdfn"+item.id} data-tooltip-html={`<p><b>Title</b>: ${item.attributes.book_title}</p><p><b>Year</b>: ${item.attributes.year}</p><p><b>Authors</b>: ${item.attributes.authors.data.map((author) => author.attributes.author_name).join(', ')}</p><p><b>Language</b>: ${item.attributes.language.data.map((lang) => lang.attributes.Language).join(', ')}</p><p><b>Law used</b>: ${item.attributes.law_used.data.map((lang) => lang.attributes.law_name).join(', ')}</p>`} style={{backgroundImage:`url('${item.attributes.book_cover.data?`${process.env.REACT_APP_BASE_MEDIA_URL}`+item.attributes.book_cover.data.attributes.url : `${process.env.REACT_APP_BASE_MEDIA_URL}/uploads/revised_design_3945d88f61.png`}')`}} className={Styles.image}><img  src={item.attributes.book_cover.data?`${process.env.REACT_APP_BASE_MEDIA_URL}`+item.attributes.book_cover.data.attributes.url : `${process.env.REACT_APP_BASE_MEDIA_URL}/uploads/revised_design_3945d88f61.png`} alt={item.book_title} /></div>
            <div className={Styles.title}>{item.attributes.book_title}</div>
            <div className={Styles.about}>{item.about}</div>
        </div>
    )
}

export default Books;