import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Arrow_img from '../elements/stories_page/Arrow_img';
import DownloadImg from '../elements/stories_page/DownloadImg';
import { getRquest } from '../../api';

//website download
export const ExcelDownloadWebsite = ({styles, arrowSvg, banName}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getRquest('Website-Blockings?populate=*&pagination[pageSize]=500');
      // Extracting and transforming data to a flat structure
      const transformedData = response.data.map(item => {
        const { attributes } = item;
        return {
          organisation_name: attributes.organisation_name,
          reported_data_of_block: attributes.reported_data_of_block,
          sources: attributes.sources,
          description: attributes.Description,
          current_status: attributes.current_status,
          number_blocked: attributes.number_blocked,
          kind_of_website: attributes.kind_of_websites.data[0]?.attributes.name || '',
          legislation_blocked_under: attributes.legislation_blocked_unders.data[0]?.attributes.name || '',
          order_issued_by: attributes.order_issued_bies.data[0]?.attributes.name || '',
          reason: attributes.reasons.data[0]?.attributes.name || '',
          type: attributes.types.data[0]?.attributes.name || ''
        };
      });
      setData(transformedData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    if (data.length === 0) {
      setError('No data available to download.');
      return;
    }

    try {
      // Create CSV content
      const header = 'This data is collected and shared by SFLC.IN and protected under CC BY-NC-SA 4.0 - ATTRIBUTION-NONCOMMERCIAL-SHAREALIKE 4.0 INTERNATIONAL\n\n';
      const csvHeader = Object.keys(data[0]).join(',') + '\n';
      const csvRows = data.map(row => 
        Object.values(row).map(value => `"${value}"`).join(',')
      ).join('\n');
      const csvContent = header + csvHeader + csvRows;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
      // Save the file using FileSaver.js
      saveAs(blob, banName+' bans data.csv');
    } catch (err) {
      console.error('Error generating CSV file:', err);
      setError('Error generating CSV file. Please try again later.');
    }
  };

  return (
    <>
     {data.length > 0 ? (
        <button onClick={downloadCSV}>
          Data is ready click to download
          <DownloadImg classname={styles} />
        </button>
      ) : (
        <button onClick={fetchData} disabled={loading}>
          {loading ? 'Fetching data...' : 'Download our dataset here'}
          <DownloadImg classname={styles} />
        </button>
      )}
    </>
  );
};

//book ban download
export const ExcelDownloadBook = ({styles, arrowSvg, banName}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getRquest('book-bans?populate=*&pagination[pageSize]=500');
      // Extracting and transforming data to a flat structure
      const transformedData = response.data.map(item => {
        const { attributes } = item;
        return {
          id: item.id,
          book_title: attributes.book_title,
          year: attributes.year,
          gender: attributes.gender,
          description: attributes.description || '',
          media_report: attributes.media_report || '',
          important: attributes.important || '',
          interesting_links: attributes.interesting_links || '',
          author_name: attributes.authors.data[0]?.attributes.author_name || '',
          category_name: attributes.categories.data[0]?.attributes.category_name || '',
          law_name: attributes.law_used.data[0]?.attributes.law_name || '',
          reason: attributes.reasons.data[0]?.attributes.Reason || '',
          initiator_name: attributes.Initiator.data[0]?.attributes.Initiator_name || '',
          language: attributes.language.data[0]?.attributes.Language || ''
        };
      });
      setData(transformedData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    if (data.length === 0) {
      setError('No data available to download.');
      return;
    }

    try {
      // Create CSV content
      const header = 'This data is collected and shared by SFLC.IN and protected under CC BY-NC-SA 4.0 - ATTRIBUTION-NONCOMMERCIAL-SHAREALIKE 4.0 INTERNATIONAL\n\n';
      const csvHeader = Object.keys(data[0]).join(',') + '\n';
      const csvRows = data.map(row => 
        Object.values(row).map(value => `"${value}"`).join(',')
      ).join('\n');
      const csvContent = header + csvHeader + csvRows;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
      // Save the file using FileSaver.js
      saveAs(blob, banName+' bans data.csv');
    } catch (err) {
      console.error('Error generating CSV file:', err);
      setError('Error generating CSV file. Please try again later.');
    }
  };

  return (
    <>
    {data.length > 0 ? (
       <button onClick={downloadCSV}>
         Data is ready click to download
         <DownloadImg classname={styles} />
       </button>
     ) : (
       <button onClick={fetchData} disabled={loading}>
         {loading ? 'Fetching data...' : 'Download our dataset here'}
         <DownloadImg classname={styles} />
       </button>
     )}
   </>
  );
};


//restrictive
export const ExcelDownloadSocial = ({styles, arrowSvg, banName}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getRquest('social-media-policies?populate=*&pagination[pageSize]=500');
      // Extracting and transforming data to a flat structure
      const transformedData = response.data.data.map(item => {
        const { attributes } = item;
        return {
          organisation: attributes.organisation,
          policy_key_points: attributes.policy_key_points,
          source: attributes.source,
          year: attributes.year,
          keywords: attributes.keywords.data[0]?.attributes.name || '',
          transgessions: attributes.transgessions.data[0]?.attributes.name || '',
          organisation_type: attributes.organisation_types.data[0]?.attributes.name || ''
        };
      });
      setData(transformedData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    if (data.length === 0) {
      setError('No data available to download.');
      return;
    }

    try {
      // Create CSV content
      const header = 'This data is collected and shared by SFLC.IN and protected under CC BY-NC-SA 4.0 - ATTRIBUTION-NONCOMMERCIAL-SHAREALIKE 4.0 INTERNATIONAL\n\n';
      const csvHeader = Object.keys(data[0]).join(',') + '\n';
      const csvRows = data.map(row => 
        Object.values(row).map(value => `"${value}"`).join(',')
      ).join('\n');
      const csvContent = header + csvHeader + csvRows;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
      // Save the file using FileSaver.js
      saveAs(blob, banName+' bans data.csv');
    } catch (err) {
      console.error('Error generating CSV file:', err);
      setError('Error generating CSV file. Please try again later.');
    }
  };

  return (
    <>
    {data.length > 0 ? (
       <button onClick={downloadCSV}>
         Data is ready click to download
         <DownloadImg classname={styles} />
       </button>
     ) : (
       <button onClick={fetchData} disabled={loading}>
         {loading ? 'Fetching data...' : 'Download our dataset here'}
         <DownloadImg classname={styles} />
       </button>
     )}
   </>
  );
};

// art bans
export const ExcelDownloadArts = ({styles, arrowSvg, banName}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getRquest('arts?populate=*&pagination[pageSize]=500');
      // Extracting and transforming data to a flat structure
      const transformedData = response?.data.map(item => {
        const { attributes } = item;
       
        return {
          art_name: attributes.art_name,
          reported_date_of_block: attributes.reported_date_of_block,
          source: attributes.Source,
          description: attributes.Description,
          whether_edits_made: attributes.whether_edits_made,
          current_status: attributes.current_status,
          image_url: attributes.image.data?.attributes.url,
          image_name: attributes.image.data?.attributes.name,
          genre: attributes.genres.data[0]?.attributes.name || '',
          initiator: attributes.initiators.data[0].attributes.name || '',
          language: attributes.languages.data[0].attributes.name || '',
          legislation_blocked_under: attributes.legislation_blocked_unders.data[0]?.attributes.name || '',
          reason: attributes.reasons.data[0].attributes.name || '',
          section_or_rule: attributes.section_or_rules.data[0].attributes.section_name || '',
          type: attributes.types.data[0].attributes.type || ''
        };
      });
      setData(transformedData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    if (data.length === 0) {
      setError('No data available to download.');
      return;
    }

    try {
      // Create CSV content
      const header = 'This data is collected and shared by SFLC.IN and protected under CC BY-NC-SA 4.0 - ATTRIBUTION-NONCOMMERCIAL-SHAREALIKE 4.0 INTERNATIONAL\n\n';
      const csvHeader = Object.keys(data[0]).join(',') + '\n';
      const csvRows = data.map(row => 
        Object.values(row).map(value => `"${value}"`).join(',')
      ).join('\n');
      const csvContent = header + csvHeader + csvRows;

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
      // Save the file using FileSaver.js
      saveAs(blob, banName+' bans data.csv');
    } catch (err) {
      console.error('Error generating CSV file:', err);
      setError('Error generating CSV file. Please try again later.');
    }
  };

  return (
    <>
    {data.length > 0 ? (
       <button onClick={downloadCSV}>
         Data is ready click to download
         <DownloadImg classname={styles} />
       </button>
     ) : (
       <button onClick={fetchData} disabled={loading}>
         {loading ? 'Fetching data...' : 'Download our dataset here'}
         <DownloadImg classname={styles} />
       </button>
     )}
   </>
  );
};

