import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { getRquest } from '../../../api';

const StackedBarChart = () => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getRquest('arts?populate=reasons,section_or_rules,image');
      const apiResponse = response;
      renderMainChart(apiResponse);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

 
  const renderMainChart = (apiResponse) => {
    try {
      const reasons = [...new Set(apiResponse.data.map(book => {
        if (!book.attributes.reasons || !book.attributes.reasons.data[0]) {
          console.error('Missing reason data for book:', book);
          return undefined;
        }
        return book.attributes.reasons.data[0].attributes.name;
      }))].filter(Boolean);

      const laws = [...new Set(apiResponse.data.map(book => {
        if (!book.attributes.section_or_rules || !book.attributes.section_or_rules.data[0]) {
          console.error('Missing section_or_rules data for book:', book);
          return undefined;
        }
        return book.attributes.section_or_rules.data[0].attributes.section_name;
      }))].filter(Boolean);

      const colors =["#2085D6","#44325E","#78A57099","#BD0101","#FFD450","#F29454","#252525","#2085D6","#44325E","#78A57099","#BD0101","#FFD450","#F29454","#252525","#2085D6","#44325E","#78A57099","#BD0101","#FFD450","#F29454","#252525"]
      const seriesData = laws.map((law,index) => {
        return {
          name: law,
          data: reasons.map(reason => {
            const filteredBooks = apiResponse.data.filter(book =>
              book.attributes.reasons.data[0].attributes.name === reason &&
              book.attributes.section_or_rules.data[0].attributes.section_name === law
            );
            return filteredBooks.length === 0 ? null : filteredBooks.length;}),
          color:colors[index]
        };
      });

      setSeries(seriesData);
      setOptions({
        chart: {
          fontFamily:"Epilogue",
          height: 'auto',
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 10,
                style: {
                  fontSize: '13px',
                  fontWeight: 500,
                  
                },
              },
            },
          },
        },
      
        xaxis: {
          categories: reasons,
          title: {
            text: 'Section or rules',
            style:{
              cssClass: 'apexcharts-xaxis-label',
              fontWeight:550,
              fontSize: 14
            },
            offsetY:-20

          },
          labels:{
            show:false
          }
        },
        yaxis: {
          title: {
           
            text: 'Reasons',
            style:{
              fontWeight:550,
              fontSize: 14
            }
          }
        },
       
        stroke: {
          width: 2,
          colors: ['var(--background)']
      },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          offsetX: 70,
          // offsetY: 20
        },
        responsive: [
          {
            breakpoint: 500,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
             
            }
          }
        ]
      });
    } catch (error) {
      console.error('Error processing data:', error);
    }
  };

  return (
    <div id="chart" style={{width:"100%"}}>
      <Chart options={options} series={series} type="bar" height={"auto"} />
    </div>
  );
};

//add for custom tooltip 

//  tooltip: {
//           custom: ({ series, seriesIndex, dataPointIndex, w }) => {
//             const book = apiResponse.data.find(book =>
//               book.attributes.reasons.data[0].attributes.name === reasons[dataPointIndex] &&
//               book.attributes.section_or_rules.data[0].attributes.section_name === laws[seriesIndex]
//             );

//             if (book) {
//               const { art_name, reported_date_of_block, Source, Description, image,section_or_rules } = book.attributes;
//               const imageUrl = image.data.attributes.url;

//             //   return `<div style="padding:10px;">
//             //   // <strong>${art_name}</strong><br/>
//             //   Reported Date: ${section_or_rules.data[0].attributes.section_name}<br/>
//             //   // Source: <a href="${Source}" target="_blank">${"Link"}</a><br/>
//             //   <img src="${imageUrl}" alt="${art_name}" style="width:100px; height:auto;"/>
//             // </div>`;
//   // }

//               return `<div style="padding:10px;font-family:Epilogue">
                       
//                         Section or rules: ${section_or_rules.data[0].attributes.section_name}<br/>
                     
//                       </div>`;
//             }
//             return '';
//           }
//         },

export default StackedBarChart;